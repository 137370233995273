.summary-card {
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.summary-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.summary-icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.summary-value {
    font-size: 24px;
    color: #333;
}

@media (max-width: 768px) {
    .summary-card {
        margin-bottom: 16px;
    }
}